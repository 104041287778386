import { Component, Host, h } from '@stencil/core';

@Component({
  tag: 'x-section',
  styleUrl: 'x-section.sass',
})
export class XSection {
  render() {
    return (
      <Host>
        <slot></slot>
      </Host>
    );
  }
}
